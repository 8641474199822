<template>
  <div class="container">
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2>edytuj</h2>
        </b-card-title>
      </b-card-header>
      <div class="form-group">
        <label for="title">Dzień tygodnia: </label>
        <!-----bootstrap form select-->
        <b-form-select
          id="input-3"
          v-model="week_day"
          :options="week_days"
          required
          @change="CheckWorkingTime()"
        />
      </div>
    </b-card>
    <b-card v-if="working_time.end">
      <b-card-header>
        <b-card-title>
          <h2>godziny pracy</h2>
        </b-card-title>
      </b-card-header>
      <!----render time info-->
      <div>
        <div class="form-group">
          <label for="title">Początek pracy: </label>
          <input
            id="title"
            v-model="working_time.start"
            type="time"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="title">Koniec pracy: </label>
          <input
            id="title"
            v-model="working_time.end"
            type="time"
            class="form-control"
          >
        </div>
        <button
          class="btn btn-success"
          style="width:100%"
          @click="UpdateWorkingTime ()"
        >
          <feather-icon icon="SaveIcon" /> Zapisz
        </button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCalendar, BCalendarDay, BCard, BForm, BFormSelect,
} from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  name: 'Calendar',
  components: {
    BCalendar,
    BCalendarDay,
    BCard,
    BForm,
    BFormSelect,
  },
  data() {
    return {
      title: 'Kalendarz',
      week_days: [
        {
          value: 1,
          text: 'Poniedziałek',
        },
        {
          value: 2,
          text: 'Wtorek',
        },
        {
          value: 3,
          text: 'Środa',
        },
        {
          value: 4,
          text: 'Czwartek',
        },
        {
          value: 5,
          text: 'Piątek',
        },
        {
          value: 6,
          text: 'Sobota',
        },
        {
          value: 7,
          text: 'Niedziela',
        },
      ],
      week_day: {},
      working_time: [],
    }
  },
  methods: {
    CheckWorkingTime() {
      swal.fire({
        title: 'Dzień tygodnia',
        text: this.week_day,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      })

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Offer/${this.$route.params.id}`, config)
        .then(response => {
          this.working_time = response.data.offer.hours[this.week_day]
          this.working_time.start = this.working_time.start_time
          this.working_time.end = this.working_time.end_time
        })
    },
    UpdateWorkingTime() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.put(`${process.env.VUE_APP_API_URL}Offer/hours/${this.$route.params.id}`, {
        weekday: this.week_day,
        start_time: this.working_time.start,
        end_time: this.working_time.end,
      }, config)

      swal.fire({
        title: 'Godziny pracy',
        text: 'Zapisano zmiany',
        icon: 'success',
        timer: 3000,
        timerProgressBar: true,
      })
    },
  },

}
</script>

<style>
.b-calendar-day__event {
    background-color: #f0f0f0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -5px;
}
</style>
